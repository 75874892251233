.App {
  text-align: center;
  overflow: hidden;
  /* height: 1000px; */
}

* {
  box-sizing: border-box;
  /* overflow: hidden; */
}


/* @font-face {
  font-family: 'MyCategoris';
  src: url('./font-self/Caveat/static/Caveat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'MyCategorisName';
  src: url('./font-self/Amatic_SC/AmaticSC-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MyCard';
  src: url('./font-self/Yatra_One/YatraOne-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MyAbout';
  src: url('./font-self/Vollkorn/Vollkorn-Italic-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'CategorisName2';
  src: url('./font-self/Neucha/Neucha-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'PersonPageHeader';
  src: url('./font-self/Roboto/Roboto-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'PersonPageHeaderText';
  src: url('./font-self/Roboto/Roboto-Regular.ttf') format('truetype');
} */





@font-face {
  font-family: 'MyCategoris';
  src: url('../public/font-self/Caveat/static/Caveat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'MyCategorisName';
  src: url('../public/font-self/Amatic_SC/AmaticSC-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MyCard';
  src: url('../public/font-self/Yatra_One/YatraOne-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MyAbout';
  src: url('../public/font-self/Vollkorn/Vollkorn-Italic-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'CategorisName2';
  src: url('../public/font-self/Neucha/Neucha-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'PersonPageHeader';
  src: url('../public/font-self/Roboto/Roboto-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'PersonPageHeaderText';
  src: url('../public/font-self/Roboto/Roboto-Regular.ttf') format('truetype');
}




