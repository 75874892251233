.loader-top {
    margin: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    overflow: hidden;
}


.flipping-cards {
display: flex;
justify-content: space-between;
width: 610px; /* Adjust based on the number of cards and their width */
}

.logo-leader {
    width: 50px;
    height: 60px;
}

.card-loader {
width: 60px;
height: 70px;
background-color: #FF5C35;
color: white;
display: flex;
justify-content: center;
align-items: center;
font-size: 38px;
font-weight: bold;
border-radius: 10px;
backface-visibility: hidden;
transform-style: preserve-3d;
animation: flip 3.7s infinite;
}

.card-loader:nth-child(1) {
animation-delay: 0s;
}

.card-loader:nth-child(2) {
animation-delay: 0.3s;
}

.card-loader:nth-child(3) {
animation-delay: 0.6s;
}

.card-loader:nth-child(4) {
animation-delay: 0.9s;
}

.card-loader:nth-child(5) {
animation-delay: 1.2s;
}

.card-loader:nth-child(6) {
animation-delay: 1.5s;
}

.card-loader:nth-child(7) {
animation-delay: 1.8s;
}
.card-loader:nth-child(8) {
 animation-delay: 2.1s;
}

.card-loader:nth-child(9) {
animation-delay: 2.4s;
}
.card-loader:nth-child(10) {
animation-delay: 2.7s;
 }
        

@keyframes flip {
0%, 100% {
    transform: rotateY(0deg);
}
50% {
    transform: rotateY(180deg);
}
}



@media screen and (max-width: 767px) {
    .loader-top {
        margin: 0;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: black;
        overflow: hidden;
    }
    

    .flipping-cards {
        padding-bottom: 200px;
        display: flex;
        justify-content: space-between;
        width: 310px; /* Adjust based on the number of cards and their width */
        }
        .logo-leader {
            width: 25px;
            height: 30px;
        }

    .card-loader {
        width: 30px;
        height: 40px;
        background-color: #FF5C35;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        border-radius: 5px;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        animation: flip 2.7s infinite;
        }
}

@media screen and (max-width: 325px) {
    .loader-top {
        margin: 0;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: black;
        overflow: hidden;
    }
    

    .flipping-cards {
        padding-bottom: 200px;
        display: flex;
        justify-content: space-between;
        width: 210px; /* Adjust based on the number of cards and their width */
        }
        .logo-leader {
            width: 20px;
            height: 25px;
        }

    .card-loader {
        width: 25px;
        height: 35px;
        background-color: #FF5C35;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        border-radius: 4px;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        animation: flip 2.7s infinite;
        }
}